.cut-off-time-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-left: 0;
  margin-top: 5%;

  form {
    width: 80%;
    padding: 10px;
  }

  .store-id {
    padding: 0.5rem 0px;
  }

  .store-name-devider {
    margin: 5px 0px;
  }
}
