.update-time-slot-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-top: 5%;
    form {
        max-width: 500px;
        width: 80%;
       // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 10px;
    }

    label {
       font-weight: bold;
       margin-bottom: 0px;
    }
    .sub-label {
        font-size: normal;
        font-weight: normal;
    }

    .label-container {
        text-align: right;
    }

    .w-30 {
        width: 30%;
    }
    .input {
        width: 70%;
    }
}