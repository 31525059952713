.update-time-slot-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-left: 0;
  margin-top: 5%;

  form {
    max-width: 800px;
    width: 80%;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
  }

  label {
    font-weight: bold;
    margin-bottom: 0px;
  }
  .sub-label {
    font-size: normal;
    font-weight: normal;
  }

  .label-container {
    text-align: right;
  }

  .w-30 {
    width: 23%;
  }
  .w-33 {
    width: 35%;
  }
  .input {
    width: 70%;
  }

  .mb-3 {
    margin-bottom: 30px !important;
  }

  .w-90 {
    width: 90%;
  }
  .w-82 {
    width: 69%;
  }
  .w-75 {
    width: 90%;
  }
  .w-16 {
    width: 16%;
  }
}
