.public-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .public-container {
    width: 100%;
  }
}

.private-main {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .private-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll !important;
    .container {
      height: 100%;
    }
  }
}