.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    form {
        max-width: 375px;
        width: 80%;
       // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 10px;
    }

    label {
       font-weight: bold; 
    }

    .w-30 {
        width: 30%;
    }
    .input {
        width: 70%;
    }
}