.existing-time-slot-table {
    th, td {
        text-align: center;
        padding: 1.5rem 0px;
    }
    .pl-10 {
        padding-left: 10px;
    }
    .pagination-select {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 0px 10px 0px 10px;
        label {
            font-size: 14px;
            margin: 0;
            margin-right: 5px;
        }
        select {
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 20px 0;
            border: 1px solid #ccc;
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2326749a'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
            background-color: #fff;
            background-size: 15px;
            background-position: calc(100% - 5px) 16px;
            background-repeat: no-repeat;
            padding-right: 20px;
            margin: 0;
            padding-left: 10px;
            color: #0d6efd;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}