.update-time-slot-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-left: 0;
  margin-top: 8%;
  margin-bottom: 100px;

  form {
    max-width: 100%;
    width: 100%;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
  }

  label {
    font-weight: bold;
    margin-bottom: 0px;
  }
  .sub-label {
    font-size: normal;
    font-weight: normal;
  }

  .label-container {
    text-align: right;
  }

  .w-30 {
    width: 25%;
  }
  .input {
    width: 60%;
  }
  .ml-3 {
    margin-left: 10px;
  }
  .th-section,
  .en-section {
    width: 50%;
    border: solid;
    border-radius: 10px;
    padding: 20px;
    border-width: 1px;
    border-color: rgb(153, 153, 153);
  }
  .open {
    width: 50%;
  }
  .section-wrapper {
    padding: 0 20px;
    column-gap: 30px;
  }
  .action-wrapper {
    padding: 0 20px;
    margin-top: 20px;
  }
}

.version-wrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 20px;
}

.version-wrapper h4 {
  margin: 0 !important;
}

.version-wrapper h3 {
  margin: 0;
}

.version-wrapper img {
  width: 30px;
}
