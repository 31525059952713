.time-slot-template-table {
  th,
  td {
    text-align: center;
  }
  .pl-10 {
    padding-left: 10px;
  }
}
.search-input {
  width: unset;
}
.invalid-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
  border-radius: 5px;
}
th {
  padding: 1.5rem !important;
}
td {
  vertical-align: middle;
}
